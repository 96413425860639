import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Row, Col, Image } from "react-bootstrap"

//images imports
import KeepWarm1 from "./../images/keep yourself warm/fp-keep yourself warm.png"
import KeepWarm2 from "./../images/keep yourself warm/keep yourself warm1.png"
import KeepWarm3 from "./../images/keep yourself warm/keep yourself warm2.png"

const KeepYourselfWarm = ({location, id}) => (
    <Layout>
        <Seo title="Keep yourself warm and burn it down" description="Keep yourself warm and burn it down, by Manon Lambeens" />
        <div style={{

        }}>
            <Row className="p-0">
            <Col xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-1">
              <h1 className="text-uppercase fs-4 text-dark">keep yourself warm and burn it down</h1>
                <p className="textAbout">
                I designed this scarf to keep you warm. We often turn up the heat while we can put on a sweater or a scarf to warm ourselves.
                </p>
                <p>
                The patterns on the scarf are on one side based on renewable energy. The other side takes inspiration from polluting sources of energy. 
                </p>
                <p>
                    PRICE:
                    <p>€30/scarf + shipping</p>
                </p>
        </Col>
      </Row>
      <Row className="p-0">
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={KeepWarm1} fluid alt="Keep yourself warm and burn it down, Manon Lambeens"/>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={KeepWarm2} fluid alt="Keep yourself warm and burn it down, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="p-0">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={KeepWarm3} fluid style={{width: '100%'}} alt="Keep yourself warm and burn it down, Manon Lambeens"/>
        </Col>
      </Row>
        </div>
    </Layout>
)

export default KeepYourselfWarm;